import styled, { keyframes } from "styled-components";

const bounce = keyframes`
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.5);
  }

  100% {
    transform: scale(1);
  }
`;

export const Wrapper = styled.div`
  p {
    color: white;
    margin: 0;

    &.title {
      font-size: 20px;
      margin-left: 30px;
    }
  }

  .section-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 18px 30px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    cursor: pointer;

    @media (max-height: 985px) {
      padding: 8px 30px;
    }
  }

  .section-right {
    svg {
      transition: transform 0.3s ease;
    }
  }

  .complete {
    animation: ${bounce} 0.3s ease forwards;
  }

  &.open {
    background-color: #f3f3f3;

    p {
      color: #011e30;
    }

    .section-right {
      svg:not(.complete) {
        transform: rotate(90deg);
      }
    }

    .section-top {
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }
  }

  .icon-wrapper {
    width: 29px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
