import React, { useContext, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { Wrapper } from "./views";
import Section from "./Section";
import { Button, SubmitForm, Modal } from "..";
import AppContext from "../../store/AppContext";
import { formIsComplete, getIncompleteFormFieldNames } from "../../store/actions";

const Configurator = () => {
  const { state, handleUpdateState } = useContext(AppContext);
  const [step, setStep] = useState(1);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState([]);

  const handleSectionClick = (name) => {
    handleUpdateState({
      [name]: {
        ...state[name],
        open: !state[name].open,
      },
    });
  };

  const handleIncrementStep = () => {
    if (formIsComplete(state)) {
      setStep(2);
    } else {
      setModalOpen(true);
      setModalContent(getIncompleteFormFieldNames(state));
    }
  };

  return (
    <Wrapper className="configurator">
      {modalOpen && (
        <Modal
          list={modalContent}
          nextCallback={() => {
            setStep(2);
            setModalOpen(false);
          }}
          backCallback={() => setModalOpen(false)}
        />
      )}

      {step === 1 && (
        <div>
          {Object.keys(state).map((item) => {
            if (state.shell.selection === "softwall") {
              // IF SOFTWALL IS SELECTED
              if (item !== "windows" && item !== "electrical" && item !== "environmental-monitoring" && item !== "customer") {
                // HIDE WINDOWS, ELECTRICAL AND ENVIRONMENTAL MONITORING. ALSO EXCLUDE CUSTOMER DATA.
                return <Section key={item} data={state[item]} itemKey={item} handleClick={() => handleSectionClick(state[item].title.toLowerCase().replace(/ /g, "-"))} />;
              }
            } else if (state.shell.selection === "hybrid") {
              // IF HYBRID IS SELECTED
              if (item !== "windows" && item !== "customer") {
                // HIDE WINDOWS. ALSO EXCLUDE CUSTOMER DATA.
                return <Section key={item} data={state[item]} itemKey={item} handleClick={() => handleSectionClick(state[item].title.toLowerCase().replace(/ /g, "-"))} />;
              }
            } else {
              // HARDWALL IS SELECTED. EXCLUDE CUSTOMER DATA AND SHOW ALL OTHER SECTIONS.
              return item !== "customer" && <Section key={item} data={state[item]} itemKey={item} handleClick={() => handleSectionClick(state[item].title.toLowerCase().replace(/ /g, "-"))} />;
            }

            return null;
          })}
        </div>
      )}

      {step === 2 && (
        <>
          <SubmitForm />
        </>
      )}

      {step === 1 && (
        <div style={{ padding: 30, width: "100%", marginTop: 60 }}>
          <Button className="wide has-icon" onClick={() => handleIncrementStep()}>
            <span>Next</span>
            <FontAwesomeIcon icon={faChevronRight} color="#011e30" />
          </Button>
        </div>
      )}

      {step === 2 && (
        <div style={{ padding: 30, width: "100%" }}>
          <Button className="wide has-icon" onClick={() => setStep(1)}>
            <FontAwesomeIcon icon={faChevronLeft} color="#011e30" />
            <span>Back to edit</span>
          </Button>
        </div>
      )}
    </Wrapper>
  );
};

export default Configurator;
