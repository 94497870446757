import React, { useContext } from "react";
import styled from "styled-components";
import { Select } from "../../..";
import AppContext from "../../../../store/AppContext";

const InputWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: no-wrap;

  > div {
    &:first-of-type {
      margin-right: 10px;
    }

    &:last-of-type {
      margin-left: 10px;
    }
  }

  @media (max-width: 1200px) {
    flex-wrap: wrap;

    > div {
      &:first-of-type {
        margin-right: 0;
      }

      &:last-of-type {
        margin-left: 0;
      }
    }
  }
`;

const SizeForm = () => {
  const { state, handleUpdateState } = useContext(AppContext);

  const handleInputChange = (e, name) => {
    handleUpdateState({
      size: {
        ...state.size,
        [name]: e.target.value,
        complete: true,
      },
    });
  };

  return (
    <>
      <InputWrapper>
        <Select label="Length" name="length" handleChange={(e) => handleInputChange(e, "length")} value={state.size.length}>
          <option value="" disabled>
            Please select...
          </option>
          <option value="2m">2m</option>
          <option value="3m">3m</option>
          <option value="4m">4m</option>
          <option value="5m">5m</option>
          <option value="6m">6m</option>
        </Select>

        <Select label="Width" name="width" handleChange={(e) => handleInputChange(e, "width")} value={state.size.width}>
          <option value="" disabled>
            Please select...
          </option>
          <option value="2m">2m</option>
          <option value="3m">3m</option>
          <option value="4m">4m</option>
          <option value="5m">5m</option>
          <option value="6m">6m</option>
        </Select>

        <Select label="Height" name="height" handleChange={(e) => handleInputChange(e, "height")} value={state.size.height}>
          <option value="" disabled>
            Please select...
          </option>
          <option value="2.1m">2.1m</option>
          <option value="2.5m">2.5m</option>
          <option value="2.7m">2.7m</option>
        </Select>
      </InputWrapper>
    </>
  );
};

export default SizeForm;
