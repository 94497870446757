import React, { useContext } from "react";
import styled from "styled-components";
import { Input, QuantityWrapper } from "../../..";
import AppContext from "../../../../store/AppContext";
import Sink from "../../../../assets/small/sink.png";
import GarmentRail from "../../../../assets/small/garment-rail.png";
import CoatHooks from "../../../../assets/small/coat-hooks.png";
import Bin from "../../../../assets/small/bin.png";
import WorkBench from "../../../../assets/small/work-bench.png";
import Chair from "../../../../assets/small/chair.png";
import LaminarFlowUnit from "../../../../assets/small/laminar-flow-unit.png";
import BioSafetyCabinet from "../../../../assets/small/bio-safety-cabinet.png";
import TransferHatch from "../../../../assets/small/transfer-hatch.png";
import TrolleyHatch from "../../../../assets/small/trolley-hatch.png";

const InputWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const FurnitureForm = () => {
  const { state, handleUpdateState } = useContext(AppContext);

  const handleInputChange = (e) => {
    handleUpdateState({
      "furniture-&-equipment": {
        ...state["furniture-&-equipment"],
        [e.target.name]: e.target.value,
        complete: true,
      },
    });
  };

  return (
    <InputWrapper>
      <QuantityWrapper>
        <Input type="number" min="0" image={Sink} label="Sink" name="sink" handleChange={(e) => handleInputChange(e)} value={state["furniture-&-equipment"].sink.toString()} />
      </QuantityWrapper>
      <QuantityWrapper>
        <Input type="number" min="0" image={GarmentRail} label="Garment Rail" name="garment-rail" handleChange={(e) => handleInputChange(e)} value={state["furniture-&-equipment"]["garment-rail"].toString()} />
      </QuantityWrapper>
      <QuantityWrapper>
        <Input type="number" min="0" image={CoatHooks} label="Coat Hooks" name="coat-hooks" handleChange={(e) => handleInputChange(e)} value={state["furniture-&-equipment"]["coat-hooks"].toString()} />
      </QuantityWrapper>
      <QuantityWrapper>
        <Input type="number" min="0" image={Bin} label="Bin" name="bin" handleChange={(e) => handleInputChange(e)} value={state["furniture-&-equipment"].bin.toString()} />
      </QuantityWrapper>
      <QuantityWrapper>
        <Input type="number" min="0" image={WorkBench} label="Work Bench" name="work-bench" handleChange={(e) => handleInputChange(e)} value={state["furniture-&-equipment"]["work-bench"].toString()} />
      </QuantityWrapper>
      <QuantityWrapper>
        <Input type="number" min="0" image={Chair} label="Chair" name="chair" handleChange={(e) => handleInputChange(e)} value={state["furniture-&-equipment"].chair.toString()} />
      </QuantityWrapper>
      <QuantityWrapper>
        <Input type="number" min="0" image={LaminarFlowUnit} label="Laminar Flow Unit" name="laminar-flow-unit" handleChange={(e) => handleInputChange(e)} value={state["furniture-&-equipment"]["laminar-flow-unit"].toString()} />
      </QuantityWrapper>
      <QuantityWrapper>
        <Input
          type="number"
          isDisabled={state.size.height === "2.1m"}
          disabled={state.size.height === "2.1m"}
          subText={state.size.height === "2.1m" && "Not available for this height"}
          min="0"
          image={BioSafetyCabinet}
          label="Bio Safety Cabinet"
          name="biological-safe-cabinet"
          handleChange={(e) => handleInputChange(e)}
          value={state["furniture-&-equipment"]["biological-safe-cabinet"].toString()}
        />
      </QuantityWrapper>
      <QuantityWrapper>
        <Input type="number" min="0" image={TransferHatch} label="Transfer Hatch" name="transfer-hatch" handleChange={(e) => handleInputChange(e)} value={state["furniture-&-equipment"]["transfer-hatch"].toString()} />
      </QuantityWrapper>
      <QuantityWrapper>
        <Input type="number" min="0" image={TrolleyHatch} label="Trolley Hatch" name="trolley-hatch" handleChange={(e) => handleInputChange(e)} value={state["furniture-&-equipment"]["trolley-hatch"].toString()} />
      </QuantityWrapper>
    </InputWrapper>
  );
};

export default FurnitureForm;
