import React, { useContext } from "react";
import { Radio } from "../../..";
import AppContext from "../../../../store/AppContext";
import lux500 from "../../../../assets/small/500lux.png";
import lux750 from "../../../../assets/small/750lux.png";
import lux1000 from "../../../../assets/small/1000lux.png";

const LightingForm = () => {
  const { state } = useContext(AppContext);

  return (
    <>
      <Radio title="500 Lux" image={lux500} parentKey="lighting" selectionKey="lux500" selected={state.lighting.selection === "lux500"} />
      <Radio title="750 Lux" image={lux750} parentKey="lighting" selectionKey="lux750" selected={state.lighting.selection === "lux750"} />
      <Radio title="1000 Lux" image={lux1000} parentKey="lighting" selectionKey="lux1000" selected={state.lighting.selection === "lux1000"} />
    </>
  );
};

export default LightingForm;
