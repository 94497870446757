import React, { useContext } from "react";
import { Radio, Toggle } from "../../..";
import FloorBeige from "../../../../assets/small/floor-beige.png";
import FloorBlue from "../../../../assets/small/floor-blue.png";
import FloorBlack from "../../../../assets/small/floor-black.png";
import FloorThistle from "../../../../assets/small/floor-thistle.png";
import AppContext from "../../../../store/AppContext";

const FloorSwatchForm = () => {
  const { state } = useContext(AppContext);

  return (
    <>
      <Toggle parentKey="flooring" label="Do you need Flooring?" />
      {state.flooring.required && (
        <div style={{ marginTop: 30 }}>
          <Radio title="Beige" image={FloorBeige} parentKey="flooring" selectionKey="beige" selected={state.flooring.selection === "beige"} />
          <Radio title="Black" image={FloorBlack} parentKey="flooring" selectionKey="black" selected={state.flooring.selection === "black"} />
          <Radio title="Blue" image={FloorBlue} parentKey="flooring" selectionKey="blue" selected={state.flooring.selection === "blue"} />
          <Radio title="Thistle" image={FloorThistle} parentKey="flooring" selectionKey="thistle" selected={state.flooring.selection === "thistle"} />
        </div>
      )}
    </>
  );
};

export default FloorSwatchForm;
