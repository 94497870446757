import styled from "styled-components";

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ff7b00;
  border: 0;
  box-shadow: none;
  padding: 15px 40px;
  max-height: 50px;
  font-size: 20px;

  &.wide {
    width: 100%;
  }

  &.has-icon {
    justify-content: space-between;
  }
`;

export default Button;
