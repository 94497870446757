import React, { useContext } from "react";
import styled from "styled-components";
import { QuantityWrapper, Select } from "../../..";
import AppContext from "../../../../store/AppContext";
import Windows900x900 from "../../../../assets/small/windows-900x900.png";
import Windows1500x900 from "../../../../assets/small/windows-1500x900.png";

const InputWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const WindowsForm = () => {
  const { state, handleUpdateState } = useContext(AppContext);

  const handleInputChange = (e) => {
    handleUpdateState({
      windows: {
        ...state.windows,
        [e.target.name]: e.target.value,
        complete: true,
      },
    });
  };

  return (
    <InputWrapper>
      <QuantityWrapper>
        <Select label="700 x 700" image={Windows900x900} name="700x700" handleChange={(e) => handleInputChange(e, "700x700")} value={state.windows["700x700"].toString()} disabled={state.windows["1500x900"] > 0 || state.windows["900x900"] > 0}>
          <option value="0" disabled>
            Select quantity...
          </option>
          <option value="0">0</option>
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
          <option value="6">6</option>
          <option value="7">7</option>
          <option value="8">8</option>
        </Select>
      </QuantityWrapper>
      <QuantityWrapper>
        <Select label="900 x 900" image={Windows900x900} name="900x900" handleChange={(e) => handleInputChange(e, "900x900")} value={state.windows["900x900"].toString()} disabled={state.windows["1500x900"] > 0 || state.windows["700x700"] > 0}>
          <option value="0" disabled>
            Select quantity...
          </option>
          <option value="0">0</option>
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
          <option value="6">6</option>
          <option value="7">7</option>
          <option value="8">8</option>
        </Select>
      </QuantityWrapper>
      <QuantityWrapper>
        <Select label="1500 x 900" image={Windows1500x900} name="1500x900" handleChange={(e) => handleInputChange(e, "1500x900")} value={state.windows["1500x900"].toString()} disabled={state.windows["900x900"] > 0 || state.windows["700x700"] > 0}>
          <option value="0" disabled>
            Select quantity...
          </option>
          <option value="0">0</option>
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
          <option value="6">6</option>
          <option value="7">7</option>
          <option value="8">8</option>
        </Select>
      </QuantityWrapper>
    </InputWrapper>
  );
};

export default WindowsForm;
