import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faCheck } from "@fortawesome/free-solid-svg-icons";
import SectionInner from "./SectionInner";
import { Wrapper } from "./views";
import { FloorSwatchForm, LightingForm, AirconForm, WindowsForm, AirlockForm, SizeForm, ShellForm, ISOForm, FrameForm, EnvironmentalMonitoringForm, AMPForm, FurnitureForm } from "../..";
import InstallationForm from "../../Form/Forms/Installation";
import ValidationForm from "../../Form/Forms/Validation";

const Section = ({ data, itemKey, handleClick }) => {
  const { title, icon, open = false, complete = false } = data;

  const renderSectionInner = (key) => {
    switch (key) {
      case "size":
        return <SizeForm />;
      case "shell":
        return <ShellForm />;
      case "frame":
        return <FrameForm />;
      case "windows":
        return <WindowsForm />;
      case "flooring":
        return <FloorSwatchForm />;
      case "iso":
        return <ISOForm />;
      case "lighting":
        return <LightingForm />;
      case "environmental-control":
        return <AirconForm />;
      case "airlock":
        return <AirlockForm />;
      case "environmental-monitoring":
        return <EnvironmentalMonitoringForm />;
      case "electrical":
        return <AMPForm />;
      case "furniture-&-equipment":
        return <FurnitureForm />;
      case "installation":
        return <InstallationForm />;
      case "validation":
        return <ValidationForm />;
      default:
        return null;
    }
  };

  return (
    <Wrapper className={`section ${open ? "open" : ""}`}>
      <div className="section-top" onClick={() => handleClick()}>
        <div className="section-left d-flex justify-content-between align-items-center">
          <div className="icon-wrapper">
            <img style={{ imageOrientation: "none" }} src={icon} alt={`${title} icon`} />
          </div>
          <p className="title">{title}</p>
        </div>
        <div className="section-right">{complete ? <FontAwesomeIcon className="complete" icon={faCheck} color={open ? "#011e30" : "#ff7b00"} /> : <FontAwesomeIcon icon={faChevronRight} color={open ? "#011e30" : "#ff7b00"} />}</div>
      </div>

      {open && (
        <SectionInner open>
          {itemKey === "furniture-&-equipment" && <h5 style={{ marginBottom: 30 }}>Note: Biosafety Cabinets & Laminar Flow units not available in 2.1m height configurations.</h5>}
          {renderSectionInner(itemKey)}
          {itemKey === "shell" && <h5 style={{ marginTop: 30 }}>Note: if you change shell, all other selections will be reset.</h5>}
          {itemKey === "environmental-control" && <h5 style={{ marginTop: 30 }}>Note: No temperature/humidity control available for international customers.</h5>}
        </SectionInner>
      )}
    </Wrapper>
  );
};

export default Section;
