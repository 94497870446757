import React from "react";
import { Toggle } from "../../..";

const ValidationForm = () => {
  return (
    <>
      <Toggle parentKey="validation" label="Do you require Guardtech to validate your ISOPod?" />
    </>
  );
};

export default ValidationForm;
