import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { Button } from "..";
import { ModalWrapper, StyledModal, ButtonWrapper } from "./views";

const Modal = ({ list, nextCallback, backCallback }) => {
  return (
    <ModalWrapper>
      <StyledModal>
        <h3>Are you sure you wish to continue?</h3>
        <p>The following sections have been left incomplete:</p>
        <ul>
          {list.map((item, key) => (
            <li key={key}>{item.replace(/-/g, " ")}</li>
          ))}
        </ul>
        <ButtonWrapper>
          <Button className="has-icon" onClick={backCallback}>
            Edit
            <FontAwesomeIcon icon={faChevronRight} color="#011e30" />
          </Button>
          <Button className="has-icon" onClick={nextCallback}>
            Continue
            <FontAwesomeIcon icon={faChevronRight} color="white" />
          </Button>
        </ButtonWrapper>
      </StyledModal>
    </ModalWrapper>
  );
};

export default Modal;
