import React, { useContext } from "react";
import AppContext from "../../../../store/AppContext";
import { Switch, Slider, Wrapper } from "./views";

const Toggle = ({ parentKey, label }) => {
  const { state, handleUpdateState } = useContext(AppContext);

  const handleClick = (e) => {
    handleUpdateState({
      [parentKey]: {
        ...state[parentKey],
        required: e.target.checked,
        selection: !e.target.checked ? "" : state[parentKey].selection,
        complete: true,
      },
    });
  };

  return (
    <Wrapper>
      <p>{label}</p>
      <Switch>
        <input type="checkbox" onChange={handleClick} checked={state[parentKey].required} />
        <Slider className="slider" />
      </Switch>
    </Wrapper>
  );
};

export default Toggle;
