import React, { useContext } from "react";
import { Radio } from "../../..";
import AppContext from "../../../../store/AppContext";
import Iso from "../../../../assets/small/iso.png";

const ISOForm = () => {
  const { state } = useContext(AppContext);

  return (
    <>
      <Radio image={Iso} title="ISO 8" parentKey="iso" selectionKey="iso8" selected={state.iso.selection === "iso8"} />
      <Radio image={Iso} title="ISO 7" parentKey="iso" selectionKey="iso7" selected={state.iso.selection === "iso7"} />
      <Radio image={Iso} title="ISO 6" parentKey="iso" selectionKey="iso6" selected={state.iso.selection === "iso6"} />
      <Radio image={Iso} title="ISO 5" parentKey="iso" selectionKey="iso5" selected={state.iso.selection === "iso5"} />
    </>
  );
};

export default ISOForm;
