import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 20px;
  padding: 20px;
  transition: all 0.3s ease;

  @media (max-height: 985px) {
    padding: 10px 20px;
  }

  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    img {
      max-width: 110px;
      margin-right: 30px;

      @media (max-width: 768px) {
        max-width: 100px;
      }
    }

    label {
      font-size: 23px;

      @media (max-width: 768px) {
        font-size: 20px;
      }
    }

    input {
      max-width: 53px;
    }
  }

  &:hover {
    background: white;
    transform: translateY(-2px);
    box-shadow: 0px 10px 10px 5px rgba(0, 0, 0, 0.05);
  }
`;

const QuantityWrapper = ({ children }) => <Wrapper>{children}</Wrapper>;

export default QuantityWrapper;
