import React, { useContext } from "react";
import { Header, ImageBody, MainWrapper } from "./views";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import AppContext from "../../store/AppContext";
import GuardtechLogo from "../../assets/guardtech-logo.png";
import ISOPodLogo from "../../assets/isopod-logo.jpg";

const Main = () => {
  const { state } = useContext(AppContext);
  const { width } = useWindowDimensions();

  return (
    <MainWrapper className="main">
      <Header className="d-flex justify-content-between align-items-center">
        <div className="d-flex flex-column align-items-center">
          <img src={ISOPodLogo} alt="ISO Pod logo" />
          <a href="https://www.iso-pod.co.uk/" target="_blank" rel="noreferrer" style={{ color: '#FF7B01', marginTop: 5 }}>Back to ispod site</a>
        </div>
        <img src={GuardtechLogo} alt="Guardtech logo" />
      </Header>
      <ImageBody className={`d-flex justify-content-center align-items-start ${width < 768 ? "ratio ratio-1x1" : ""}`}>
        <div className="image-wrapper" style={{ pointerEvents: 'none' }}>
          <img className={`layer-1 floor-blue ${state.flooring.selection === "blue" ? "active" : "hidden"}`} src="https://api.isopodconfigurator.com/images/isopod/layer1__floor_4.png" alt="Floor Blue" />
          <img className={`layer-1 floor-black ${state.flooring.selection === "black" ? "active" : "hidden"}`} src="https://api.isopodconfigurator.com/images/isopod/layer1__floor_1.png" alt="Floor Black" />
          <img className={`layer-1 floor-thistle ${state.flooring.selection === "thistle" ? "active" : "hidden"}`} src="https://api.isopodconfigurator.com/images/isopod/layer1__floor_2.png" alt="Floor Thistle" />
          <img className={`layer-1 floor-beige ${state.flooring.selection === "beige" ? "active" : "hidden"}`} src="https://api.isopodconfigurator.com/images/isopod/layer1__floor_3.png" alt="Floor Beige" />
          <img className={`layer-2 base ${state.shell.selection === "hardwall" ? "active" : "hidden"}`} src="https://api.isopodconfigurator.com/images/isopod/layer2__main_room.png" alt="Pod" />
          <img className={`layer-2 base ${state.shell.selection === "softwall" ? "active" : "hidden"}`} src="https://api.isopodconfigurator.com/images/isopod/layer2__main_room_softwall.png" alt="Pod" />
          <img className={`layer-2 base ${state.shell.selection === "hybrid" ? "active" : "hidden"}`} src="https://api.isopodconfigurator.com/images/isopod/layer2__main_room_hybrid.png" alt="Pod" />
          <img className={`layer-3 windows-900x900 ${state.windows["700x700"] > 0 ? "active" : "hidden"}`} src="https://api.isopodconfigurator.com/images/isopod/layer3__windows.png" alt="Windows 700X700" />
          <img className={`layer-3 windows-900x900 ${state.windows["900x900"] > 0 ? "active" : "hidden"}`} src="https://api.isopodconfigurator.com/images/isopod/layer3__windows.png" alt="Windows 900X900" />
          <img className={`layer-3 windows-1500x900 ${state.windows["1500x900"] > 0 ? "active" : "hidden"}`} src="https://api.isopodconfigurator.com/images/isopod/layer3__windows_1500mmw.png" alt="Windows 1500X900" />
          <img className={`layer-4 mal ${state.airlock.mal && state.shell.selection === "softwall" ? "active" : "hidden"}`} src="https://api.isopodconfigurator.com/images/isopod/layer5__mal_softwall.png" alt="Materials Airlock" />
          <img className={`layer-4 mal ${state.airlock.pal && state.shell.selection === "softwall" ? "active" : "hidden"}`} src="https://api.isopodconfigurator.com/images/isopod/layer4__pal_softwall.png" alt="Personnel Airlock" />
          <img className={`layer-4 mal ${state.airlock.mal && state.shell.selection !== "softwall" ? "active" : "hidden"}`} src="https://api.isopodconfigurator.com/images/isopod/layer5__mal.png" alt="Materials Airlock" />
          <img className={`layer-5 pal ${state.airlock.pal && state.shell.selection !== "softwall" ? "active" : "hidden"}`} src="https://api.isopodconfigurator.com/images/isopod/layer4__pal.png" alt="Personnel Airlock" />
          <img className={`layer-6 amp-sockets ${state.electrical.required ? "active" : "hidden"}`} src="https://api.isopodconfigurator.com/images/isopod/layer6__amp_sockets.png" alt="13-AMP Sockets" />
          <img className={`layer-7 iso-8 ${state.iso.selection === "iso8" ? "active" : "hidden"}`} src="https://api.isopodconfigurator.com/images/isopod/layer7__4_ffus.png" alt="ISO 8" />
          <img className={`layer-7 iso-7 ${state.iso.selection === "iso7" ? "active" : "hidden"}`} src="https://api.isopodconfigurator.com/images/isopod/layer7__5_ffus.png" alt="ISO 7" />
          <img className={`layer-7 iso-6 ${state.iso.selection === "iso6" ? "active" : "hidden"}`} src="https://api.isopodconfigurator.com/images/isopod/layer7__6_ffus.png" alt="ISO 6" />
          <img className={`layer-7 iso-5 ${state.iso.selection === "iso5" ? "active" : "hidden"}`} src="https://api.isopodconfigurator.com/images/isopod/layer7__9_ffus.png" alt="ISO 5" />
          <img className={`layer-8 led-4 ${state.lighting.selection === "lux500" ? "active" : "hidden"}`} src="https://api.isopodconfigurator.com/images/isopod/layer8__4_leds.png" alt="4 LEDS" />
          <img className={`layer-8 led-6 ${state.lighting.selection === "lux750" ? "active" : "hidden"}`} src="https://api.isopodconfigurator.com/images/isopod/layer8__6_leds.png" alt="6 LEDS" />
          <img className={`layer-8 led-8 ${state.lighting.selection === "lux1000" ? "active" : "hidden"}`} src="https://api.isopodconfigurator.com/images/isopod/layer8__8_leds.png" alt="8 LEDS" />
          <img className={`layer-9 hvac ${state["environmental-control"].required ? "active" : "hidden"}`} src="https://api.isopodconfigurator.com/images/isopod/layer9__hvac.png" alt="HVAC" />
        </div>
      </ImageBody>
    </MainWrapper>
  );
};

export default Main;
