import React from "react";
import styled, { keyframes } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

const bounce = keyframes`
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.5);
  }

  100% {
    transform: scale(1);
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  svg {
    position: absolute;
    top: calc(50% - 8px);
    left: calc(50% - 8px);
    z-index: 10;
  }
`;

const HexagonChecked = styled.div`
  position: relative;
  width: 28px;
  height: 16.17px;
  background-color: #ff7b00;
  margin: 8.08px 0;
  animation: ${bounce} 0.3s ease forwards;

  svg {
    color: white;
  }

  &::before,
  &::after {
    content: "";
    position: absolute;
    width: 0;
    border-left: 14px solid transparent;
    border-right: 14px solid transparent;
  }

  &::before {
    bottom: 100%;
    border-bottom: 8.08px solid #ff7b00;
  }

  &::after {
    top: 100%;
    width: 0;
    border-top: 8.08px solid #ff7b00;
  }
`;

const HexagonUnChecked = styled.div`
  position: relative;
  width: 28px;
  height: 16.17px;
  background-color: #ffffff;
  margin: 8.08px 0;
  border-left: solid 2px #041c31;
  border-right: solid 2px #041c31;

  &::before,
  &::after {
    content: "";
    position: absolute;
    z-index: 1;
    width: 19.8px;
    height: 19.8px;
    -webkit-transform: scaleY(0.5774) rotate(-45deg);
    -ms-transform: scaleY(0.5774) rotate(-45deg);
    transform: scaleY(0.5774) rotate(-45deg);
    background-color: inherit;
    left: 2.1005px;
  }

  &::before {
    top: -9.8995px;
    border-top: solid 2.8284px #041c31;
    border-right: solid 2.8284px #041c31;
  }

  &::after {
    bottom: -9.8995px;
    border-bottom: solid 2.8284px #041c31;
    border-left: solid 2.8284px #041c31;
  }
`;

const HexagonCheckMark = ({ checked = false }) => {
  return (
    <Wrapper>
      <FontAwesomeIcon icon={faCheck} style={{ color: checked ? "white" : "#041c31" }} />
      {checked ? <HexagonChecked /> : <HexagonUnChecked />}
    </Wrapper>
  );
};

export default HexagonCheckMark;
