import React from "react";
import styled from "styled-components";

const Container = styled.div`
  width: 100%;
`;

const StyledInput = styled.textarea`
  display: block;
  width: 100%;
  max-width: 100%;
  height: 100px;
  padding: 10px;
  font-size: 18px;
`;

const Label = styled.label`
  font-size: 18px;
`;

const TextArea = (props) => {
  const { label, name, value, handleChange } = props;
  return (
    <Container>
      <Label htmlFor={name}>{label}</Label>
      <StyledInput {...props} onChange={handleChange} value={value} />
    </Container>
  );
};

export default TextArea;
