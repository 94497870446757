import React from "react";
import { Toggle } from "../../..";

const InstallationForm = () => {
  return (
    <>
      <Toggle parentKey="installation" label="Do you require Guardtech to install your ISOPod?" />
    </>
  );
};

export default InstallationForm;
