import SizeIconBlue from "../assets/icons/size.png";
import FrameIconBlue from "../assets/icons/frame.png";
import WindowsIconBlue from "../assets/icons/windows.png";
import FloorIconBlue from "../assets/icons/floor.png";
import IsoIconBlue from "../assets/icons/iso.png";
import LightingIconBlue from "../assets/icons/lighting.png";
import AirconIconBlue from "../assets/icons/aircon.png";
import MonitoringIconBlue from "../assets/icons/monitoring.png";
import AmpIconBlue from "../assets/icons/amp.png";
import FurnitureIconBlue from "../assets/icons/furniture.png";
import AirlockIconBlue from "../assets/icons/airlock.png";
import ShellIconBlue from "../assets/icons/shell.png";
import ValidationIconBlue from "../assets/icons/validation.png";
import InstallationIconBlue from "../assets/icons/installation.png";

export const defaultConfiguratorState = {
  shell: {
    title: "Shell",
    open: false,
    complete: true,
    icon: ShellIconBlue,
    selection: "hardwall",
  },
  size: {
    title: "Size",
    open: false,
    complete: false,
    icon: SizeIconBlue,
    length: "",
    width: "",
    height: "",
  },
  frame: {
    title: "Frame",
    open: false,
    complete: true,
    icon: FrameIconBlue,
    selection: "",
  },
  windows: {
    title: "Windows",
    open: false,
    complete: false,
    icon: WindowsIconBlue,
    "700x700": 0,
    "900x900": 0,
    "1500x900": 0,
  },
  flooring: {
    title: "Flooring",
    open: false,
    complete: false,
    required: false,
    icon: FloorIconBlue,
    selection: "",
  },
  airlock: {
    title: "Airlock",
    open: false,
    complete: false,
    icon: AirlockIconBlue,
    pal: false,
    mal: false,
  },
  iso: {
    title: "ISO",
    open: false,
    complete: false,
    icon: IsoIconBlue,
    selection: "",
  },
  lighting: {
    title: "Lighting",
    open: false,
    complete: false,
    icon: LightingIconBlue,
    selection: "",
  },
  "environmental-control": {
    title: "Environmental Control",
    open: false,
    complete: false,
    icon: AirconIconBlue,
    required: false,
    operator_comfort_only: false,
    temperature: "",
    "temperature-tolerance": "",
    humidity: "",
    "humidity-tolerance": "",
  },
  "environmental-monitoring": {
    title: "Environmental Monitoring",
    open: false,
    complete: false,
    icon: MonitoringIconBlue,
    required: false,
    selection: "",
  },
  electrical: {
    title: "Electrical",
    open: false,
    complete: false,
    icon: AmpIconBlue,
    "electrical-sockets": 0,
    "network-sockets": 0,
  },
  "furniture-&-equipment": {
    title: "Furniture & Equipment",
    open: false,
    complete: false,
    icon: FurnitureIconBlue,
    sink: 0,
    "garment-rail": 0,
    "coat-hooks": 0,
    bin: 0,
    "work-bench": 0,
    chair: 0,
    "laminar-flow-unit": 0,
    "biological-safe-cabinet": 0,
    "transfer-hatch": 0,
    "trolley-hatch": 0,
  },
  installation: {
    title: "Installation",
    open: false,
    complete: false,
    icon: InstallationIconBlue,
    required: false,
  },
  validation: {
    title: "Validation",
    open: false,
    complete: false,
    icon: ValidationIconBlue,
    required: false,
  },
  customer: {
    firstName: "",
    lastName: "",
    company: "",
    job_title: "",
    industry: "",
    email: "",
    contactNumber: "",
    country: "",
    country_code: "",
    address_search_query: "",
    address: "",
    details: "",
    marketing_opt_in: false,
    how_did_you_find_us: ""
  },
};
