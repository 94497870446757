import React, { useContext } from "react";
import { Radio, Toggle } from "../../..";
import AppContext from "../../../../store/AppContext";
import Digital from "../../../../assets/small/em-digital.png";
import Analogue from "../../../../assets/small/em-analogue.png";

const EnvironmentalMonitoringForm = () => {
  const { state } = useContext(AppContext);

  return (
    <>
      <Toggle parentKey="environmental-monitoring" label="Do you need Environmental Monitoring?" />
      {state["environmental-monitoring"].required && (
        <div style={{ marginTop: 30 }}>
          <Radio title="Analogue - Pressure Only" image={Analogue} parentKey="environmental-monitoring" selectionKey="analogue" selected={state["environmental-monitoring"].selection === "analogue"} />
          <Radio title="Digital - Temperature, Humidity &amp; Pressure" image={Digital} parentKey="environmental-monitoring" selectionKey="digital" selected={state["environmental-monitoring"].selection === "digital"} />
        </div>
      )}
    </>
  );
};

export default EnvironmentalMonitoringForm;
