export const formIsComplete = (data, returnList = false) => {
  const incompleteSelections = Object.keys(data).filter((item) => {
    if (item === "customer" || item === "aircon" || item === "furniture-&-equipment") {
      return null;
    }

    if (data.shell.selection === "softwall") {
      if (item === "windows" || item === "electrical" || item === "environmental-monitoring") {
        return null;
      }
    }

    return data[item].complete === false;
  });

  if (returnList) return incompleteSelections;

  return incompleteSelections.length === 0;
};

export const getIncompleteFormFieldNames = (data) => {
  return formIsComplete(data, true);
};

const generateImagesArray = (data) => {
  let arr = [];

  if (data.flooring.selection === "black") arr.push("layer1__floor_1.png");
  if (data.flooring.selection === "thistle") arr.push("layer1__floor_2.png");
  if (data.flooring.selection === "beige") arr.push("layer1__floor_3.png");
  if (data.flooring.selection === "blue") arr.push("layer1__floor_4.png");

  if (data.shell.selection === "softwall") arr.push("layer2__main_room_softwall.png");
  if (data.shell.selection === "hardwall") arr.push("layer2__main_room.png");
  if (data.shell.selection === "hybrid") arr.push("layer2__main_room_hybrid.png");

  if (data.windows["900x900"] > 0) arr.push("layer3__windows.png");
  if (data.windows["1500x900"] > 0) arr.push("layer3__windows_1500mmw.png");

  if (data.airlock.pal && data.shell.selection === "softwall") arr.push("layer4__pal_softwall.png");
  if (data.airlock.mal && data.shell.selection === "softwall") arr.push("layer5__mal_softwall.png");
  if (data.airlock.pal && data.shell.selection !== "softwall") arr.push("layer4__pal.png");
  if (data.airlock.mal && data.shell.selection !== "softwall") arr.push("layer5__mal.png");

  if (data.electrical.required) arr.push("layer6__amp_sockets.png");

  if (data.iso.selection === "iso8") arr.push("layer7__4_ffus.png");
  if (data.iso.selection === "iso7") arr.push("layer7__5_ffus.png");
  if (data.iso.selection === "iso6") arr.push("layer7__6_ffus.png");
  if (data.iso.selection === "iso5") arr.push("layer7__9_ffus.png");

  if (data.lighting.selection === "lux500") arr.push("layer8__4_leds.png");
  if (data.lighting.selection === "lux750") arr.push("layer8__6_leds.png");
  if (data.lighting.selection === "lux1000") arr.push("layer8__8_leds.png");

  if (data["environmental-control"].required) arr.push("layer9__hvac.png");

  return arr;
};

export const translateFormData = (data) => {
  return {
    first_name: data.customer.firstName,
    last_name: data.customer.lastName,
    company: data.customer.company,
    job_title: data.customer.job_title,
    industry: data.customer.industry,
    email: data.customer.email,
    contact_number: data.customer.contactNumber,
    install_location: data.customer.address,
    details: data.customer.details,
    marketing_opt_in: data.customer.marketing_opt_in ? "Yes" : "No",
    images: generateImagesArray(data),
    config: [
      {
        key: "size_length",
        label: "Length",
        value: data.size.length ? `${data.size.length}` : "Not specified",
      },
      {
        key: "size_width",
        label: "Width",
        value: data.size.width ? `${data.size.width}` : "Not specified",
      },
      {
        key: "size_height",
        label: "Height",
        value: data.size.height ? `${data.size.height}` : "Not specified",
      },
      {
        key: "shell",
        label: "Shell",
        value:
          data.shell.selection
            .toLowerCase()
            .split(" ")
            .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(" ") || "Not specified",
      },
      {
        key: "frame",
        label: "Frame",
        value:
          data.frame.selection
            .toLowerCase()
            .split(" ")
            .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(" ") || "Not specified",
      },
      {
        key: "900x900_windows",
        label: "Windows (900 x 900)",
        value: data.windows["900x900"] || "0",
      },
      {
        key: "1500x900_windows",
        label: "Windows (1500 x 900)",
        value: data.windows["1500x900"] || "0",
      },
      {
        key: "floor",
        label: "Floor",
        value: data.flooring.required
          ? data.flooring.selection
              .toLowerCase()
              .split(" ")
              .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
              .join(" ") || "Not specified"
          : "None",
      },
      {
        key: "personnel_air_lock",
        label: "Personnel Air Lock",
        value: data.airlock.pal ? "Yes" : "No",
      },
      {
        key: "material_air_lock",
        label: "Materials Air Lock",
        value: data.airlock.mal ? "Yes" : "No",
      },
      {
        key: "iso",
        label: "ISO",
        value: data.iso.selection ? `${data.iso.selection.toUpperCase()}` : "Not specified",
      },
      {
        key: "lighting",
        label: "Lighting",
        value: data.lighting.required ? "Yes" : "No",
      },
      {
        key: "lighting_lumens",
        label: "Lighting Lumens",
        value: data.lighting.selection ? `${data.lighting.selection.toUpperCase()}` : "Not specified",
      },
      {
        key: "environmental-control",
        label: "Environmental Control",
        value: data["environmental-control"].required ? "Yes" : "No",
      },
      {
        key: "aircon_temperature",
        label: "Temperature",
        value: data["environmental-control"].operator_comfort_only ? "Operator comfort only" : data["environmental-control"].temperature ? `${data["environmental-control"].temperature} &deg;c` : "Not specified",
      },
      {
        key: "aircon_temperature_tolerance",
        label: "Temperature Tolerance",
        value: data["environmental-control"].operator_comfort_only ? "Operator comfort only" : data["environmental-control"]["temperature-tolerance"] ? `${data["environmental-control"]["temperature-tolerance"]} &deg;c` : "Not specified",
      },
      {
        key: "aircon_humidity",
        label: "Humidity",
        value: data["environmental-control"].operator_comfort_only ? "Operator comfort only" : data["environmental-control"].humidity ? `${data["environmental-control"].humidity}%` : "Not specified",
      },
      {
        key: "aircon_humidity_tolerance",
        label: "Humidity Tolerance",
        value: data["environmental-control"].operator_comfort_only ? "Operator comfort only" : data["environmental-control"]["humidity-tolerance"] ? `${data["environmental-control"]["humidity-tolerance"]}%` : "Not specified",
      },
      {
        key: "environmental_monitoring",
        label: "Environmental Monitoring",
        value: (data["environmental-monitoring"].selection === "digital" && "Digital - Temperature, Humidity & Pressure") || (data["environmental-monitoring"].selection === "analogue" && "Analogue – Pressure Only") || "Not required",
      },
      {
        key: "13amp_sockets",
        label: "13-Amp Electrical Sockets",
        value: data.electrical["electrical-sockets"].toString(),
      },
      {
        key: "network_sockets",
        label: "CAT6 Network Sockets",
        value: data.electrical["network-sockets"].toString(),
      },
      {
        key: "sink",
        label: "Sink",
        value: data["furniture-&-equipment"].sink.toString(),
      },
      {
        key: "garment_rail",
        label: "Garment Rail",
        value: data["furniture-&-equipment"]["garment-rail"].toString(),
      },
      {
        key: "coat_hooks",
        label: "Coat Hooks",
        value: data["furniture-&-equipment"]["coat-hooks"].toString(),
      },
      {
        key: "bin",
        label: "Bin",
        value: data["furniture-&-equipment"].bin.toString(),
      },
      {
        key: "chair",
        label: "Chair",
        value: data["furniture-&-equipment"].chair.toString(),
      },
      {
        key: "laminar_flow_unit",
        label: "Laminar Flow Unit",
        value: data["furniture-&-equipment"]["laminar-flow-unit"].toString(),
      },
      {
        key: "biological_safe_cabinet",
        label: "Bio Safety Cabinet",
        value: data["furniture-&-equipment"]["biological-safe-cabinet"].toString(),
      },
      {
        key: "transfer_hatch",
        label: "Transfer Hatch",
        value: data["furniture-&-equipment"]["transfer-hatch"].toString(),
      },
      {
        key: "trolley_hatch",
        label: "Trolley Hatch",
        value: data["furniture-&-equipment"]["trolley-hatch"].toString(),
      },
      {
        key: "installation",
        label: "Installation",
        value: data.installation.required ? "Yes" : "No",
      },
      {
        key: "validation",
        label: "Validation",
        value: data.validation.required ? "Yes" : "No",
      },
    ],
  };
};
