import styled from "styled-components";

export const FormWrapper = styled.div`
  padding: 30px;
  color: white;

  h3 {
    display: block;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    padding-bottom: 20px;
    margin-bottom: 20px;
  }

  form {
    > div {
      margin-bottom: 20px;

      @media (max-width: 768px) {
        margin-bottom: 0;
      }

      &:last-of-type {
        margin-bottom: 40px;
      }

      label {
        color: white;
      }
    }
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;

  @media (max-width: 768px) {
    flex-direction: column;
  }

  > div:nth-child() {
    margin-right: 10px;

    @media (max-width: 768px) {
      margin-right: 0;
      margin-bottom: 20px;
    }
  }

  > div:nth-child(2) {
    margin-left: 20px;

    @media (max-width: 768px) {
      margin-left: 0;
      margin-bottom: 20px;
    }
  }

  button {
    margin-left: 20px;

    ${(props) =>
      props.isPostcodeLookup &&
      `
        max-width: 40%;
        padding: 15px 0;
      `}
  }
`;

export const Message = styled.div`
  padding: 20px;
  margin-top: 30px;
  font-size: 20px;
  color: white;
  background-color: ${(props) => (props.type === "success" ? "green" : "red")};
`;
