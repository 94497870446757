import React, { useContext } from "react";
import styled from "styled-components";
import { Toggle, Input, Checkbox } from "../../..";
import AppContext from "../../../../store/AppContext";

const InputWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: no-wrap;
  margin-top: 30px;

  > div {
    &:first-of-type {
      margin-right: 10px;
    }

    &:last-of-type {
      margin-left: 10px;
    }
  }

  @media (max-width: 1200px) {
    flex-wrap: wrap;

    > div {
      &:first-of-type {
        margin-right: 0;
      }

      &:last-of-type {
        margin-left: 0;
      }
    }
  }
`;

const AirconForm = () => {
  const { state, handleUpdateState } = useContext(AppContext);

  const handleInputChange = (e, name) => {
    handleUpdateState({
      "environmental-control": {
        ...state["environmental-control"],
        [name]: e.target.value,
        complete: true,
      },
    });
  };

  const handleCheckboxChange = (e) => {
    handleUpdateState({
      "environmental-control": {
        ...state["environmental-control"],
        operator_comfort_only: e.target.checked,
        complete: true,
      },
    });
  };

  return (
    <>
      <Toggle parentKey="environmental-control" label="Do you need Air Conditioning?" />
      {state["environmental-control"].required && (
        <>
          <div style={{ display: "flex", alignItems: "center", margin: "20px 0" }}>
            <Checkbox type="checkbox" name="operator_comfort_only" additionalText="&deg;C" onChange={(e) => handleCheckboxChange(e)} checked={state["environmental-control"].operator_comfort_only} />
            <label htmlFor="operator_comfort_only" style={{ marginLeft: 20, marginTop: 3, fontSize: 20 }}>
              Operator comfort only?
            </label>
          </div>
          {!state["environmental-control"].operator_comfort_only && (
            <>
              <InputWrapper>
                <Input label="Temperature" name="temperature" additionalText="&deg;C" handleChange={(e) => handleInputChange(e, "temperature")} value={state["environmental-control"].temperature} />
                <Input label="Tolerance" name="temperature-tolerance" additionalText="&deg;C" handleChange={(e) => handleInputChange(e, "temperature-tolerance")} value={state["environmental-control"]["temperature-tolerance"]} />
              </InputWrapper>
              <InputWrapper>
                <Input label="Humidity" name="humidity" additionalText="%" handleChange={(e) => handleInputChange(e, "humidity")} value={state["environmental-control"].humidity} />
                <Input label="Tolerance" name="humidity-tolerance" additionalText="%" handleChange={(e) => handleInputChange(e, "humidity-tolerance")} value={state["environmental-control"]["humidity-tolerance"]} />
              </InputWrapper>
            </>
          )}
        </>
      )}
    </>
  );
};

export default AirconForm;
