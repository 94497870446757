import styled from "styled-components";

export const Wrapper = styled.section`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  background-color: #011e30;
  min-height: calc(100vh - 60px);

  @media (max-width: 768px) {
    height: auto;
    max-height: none;
  }
`;
