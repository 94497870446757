import React, { useState, useEffect } from "react";
import AppContext from "../../store/AppContext";
import { defaultConfiguratorState } from "../../store/defaultState";
import { Main, Configurator } from "..";
import { Section } from "./views";

const App = () => {
  const [state, updateState] = useState(defaultConfiguratorState);

  useEffect(() => {
    sessionStorage.getItem("form-data") ? updateState(JSON.parse(sessionStorage.getItem("form-data"))) : sessionStorage.setItem("form-data", JSON.stringify({ ...defaultConfiguratorState }));
  }, []);

  const handleUpdateState = (newState) => {
    let stateCopy = {};

    for (const entry in state) {
      stateCopy[entry] = state[entry];
      if (entry !== "customer") {
        stateCopy[entry].open = false;
      }
    }

    updateState({ ...stateCopy, ...newState });
    sessionStorage.setItem("form-data", JSON.stringify({ ...stateCopy, ...newState }));
  };

  const value = { state, handleUpdateState };

  return (
    <AppContext.Provider value={value}>
      <Section className="main-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-8 col-md-6">
              <Main />
            </div>
            <div className="col-lg-4 col-md-6">
              <Configurator />
            </div>
          </div>
        </div>
      </Section>
    </AppContext.Provider>
  );
};

export default App;
