import React from "react";
import styled from "styled-components";

const Container = styled.div`
  width: 100%;
`;

const InputWrapper = styled.div`
  position: relative;

  span {
    position: absolute;
    right: 15px;
    top: 50%;
    font-size: 35px;
    line-height: 0;
    color: rgba(0, 0, 0, 0.4);
  }
`;

const StyledInput = styled.select`
  width: 100%;
  max-width: 100%;
  height: 50px;
  padding: 0 10px;
  font-size: 18px;
`;

const Label = styled.label`
  font-size: 18px;
`;

const Select = (props) => {
  const { label, name, value, handleChange, children, image } = props;
  return (
    <Container>
      <div>
        {image && <img src={image} alt={`${label}`} />}
        <Label htmlFor={name}>{label}</Label>
      </div>
      <InputWrapper>
        <StyledInput type="select" {...props} onChange={handleChange} value={value}>
          {children}
        </StyledInput>
      </InputWrapper>
    </Container>
  );
};

export default Select;
