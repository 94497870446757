import styled, { keyframes } from "styled-components";

const SlideDown = keyframes`
  from {
    opacity: 0;
    visibility: hidden;
  }

  to {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
`;

const SlideUp = keyframes`
  from {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }

  to {
    opacity: 0;
    visibility: hidden;
    transform: translateY(-60px);
  }
`;

export const Header = styled.header`
  margin-right: 50px;

  img:first-of-type {
    max-width: 160px;

    @media (max-width: 992px) {
      max-width: 80px;
    }
  }

  img:last-of-type {
    max-width: 305px;

    @media (max-width: 992px) {
      max-width: 150px;
    }
  }

  @media (max-width: 992px) {
    img {
      max-width: 40%;
    }
  }

  @media (max-width: 768px) {
    margin-right: 0;
  }
`;

export const MainWrapper = styled.section`
  position: sticky;
  top: 0;

  @media (max-width: 768px) {
    position: relative;
  }
`;

export const ImageBody = styled.section`
  .image-wrapper {
    position: relative;
    width: 1250px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 768px) {
      height: auto;
      overflow: visible;
    }

    img {
      position: absolute;
      top: -150px;
      left: 0;
      max-width: 100%;
      opacity: 0;
      visibility: hidden;
      transform: translateY(-60px);

      @media (max-width: 991px) {
        top: 0;
      }

      &.layer-1 {
        z-index: 0;
      }

      &.layer-2 {
        z-index: 1;
      }

      &.layer-3 {
        z-index: 2;
      }

      &.layer-4 {
        z-index: 3;
      }

      &.layer-5 {
        z-index: 4;
      }

      &.layer-6 {
        z-index: 5;
      }

      &.layer-7 {
        z-index: 6;
      }

      &.layer-8 {
        z-index: 7;
      }

      &.layer-9 {
        z-index: 8;
      }

      &.active {
        opacity: 1;
        visibility: visible;
        animation: ${SlideDown} 0.3s ease forwards;
      }

      &.hidden {
        animation: ${SlideUp} 0.3s ease forwards;
      }
    }
  }

  @media (max-width: 768px) {
    margin-bottom: 30px;
  }
`;
