import styled, { keyframes } from "styled-components";

const spin = keyframes`
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div`
  margin: 0 0 0 30px;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 2px solid rgba(0, 0, 0, 0.2);
  border-right: 2px solid rgba(0, 0, 0, 0.2);
  border-bottom: 2px solid rgba(0, 0, 0, 0.2);
  border-left: 2px solid #000000;
  transform: translateZ(0);
  animation: ${spin} 0.5s infinite linear;
  border-radius: 50%;
  width: 30px;
  height: 30px;

  &::after {
    border-radius: 50%;
    width: 30px;
    height: 30px;
  }
`;

export default Spinner;
