import React, { useContext } from "react";
import { Radio } from "../../..";
import AppContext from "../../../../store/AppContext";
import Mal from "../../../../assets/small/mal.png";
import Pal from "../../../../assets/small/pal.png";

const AirlockForm = () => {
  const { state } = useContext(AppContext);

  return (
    <>
      <Radio image={Mal} title="Materials Air Lock" parentKey="airlock" selectionKey="mal" selected={state.airlock.mal} />
      <Radio image={Pal} title="Personnel Air Lock" parentKey="airlock" selectionKey="pal" selected={state.airlock.pal} />
    </>
  );
};

export default AirlockForm;
