import React, { useContext } from "react";
import styled from "styled-components";
import AppContext from "../../../../store/AppContext";
import { defaultConfiguratorState } from "../../../../store/defaultState";
import HexagonCheckMark from "../../../Icons/hexagon";

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: transparent;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  padding: 20px;
  margin-bottom: 20px;
  transition: all 0.3s ease;
  cursor: pointer;

  @media (max-height: 985px) {
    padding: 10px 20px;
  }

  > div {
    display: flex;
    align-items: center;
  }

  img {
    max-width: 110px;
    margin-right: 30px;
  }

  p {
    font-size: 25px;
  }

  &:hover,
  &.is-selected {
    background: white;
    transform: translateY(-2px);
    box-shadow: 0px 10px 10px 5px rgba(0, 0, 0, 0.05);
  }
`;

const Radio = ({ parentKey, selectionKey, image, title, selected = false }) => {
  const { state, handleUpdateState } = useContext(AppContext);

  const handleClick = () => {
    if (parentKey === "shell") {
      return handleUpdateState({
        ...defaultConfiguratorState,
        shell: {
          ...state.shell,
          selection: selectionKey,
          open: true,
          complete: true,
        },
      });
    }

    if (parentKey === "airlock" || parentKey === "furniture") {
      handleUpdateState({
        [parentKey]: {
          ...state[parentKey],
          [selectionKey]: !state[parentKey][selectionKey],
          complete: true,
        },
      });
    } else {
      handleUpdateState({
        [parentKey]: {
          ...state[parentKey],
          selection: state[parentKey].selection === selectionKey ? "" : selectionKey,
          complete: true,
        },
      });
    }
  };

  return (
    <Wrapper onClick={handleClick} className={selected ? "is-selected" : ""}>
      <div>
        {image && <img src={image} alt={`${title}`} />}
        <p>{title}</p>
      </div>
      <HexagonCheckMark checked={selected} />
    </Wrapper>
  );
};

export default Radio;
