import React, { useContext } from "react";
import { Radio } from "../../..";
import Steel from "../../../../assets/small/frame-steel.png";
import AppContext from "../../../../store/AppContext";

const FrameForm = () => {
  const { state } = useContext(AppContext);

  return (
    <>
      <Radio title="Stainless Steel" image={Steel} parentKey="frame" selectionKey="steel" selected={state.frame.selection === "steel"} />
      <Radio title="Aluminium" image={Steel} parentKey="frame" selectionKey="aluminium" selected={state.frame.selection === "aluminium"} />
    </>
  );
};

export default FrameForm;
