import styled from "styled-components";

export const ModalWrapper = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 999999;
`;

export const StyledModal = styled.div`
  background-color: white;
  padding: 30px;
  width: 90%;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 30px;

  button {
    width: 100%;

    &:first-of-type {
      margin-right: 10px;

      @media (max-width: 992px) {
        margin-right: 0;
      }
    }

    &:last-of-type {
      margin-left: 10px;
      background-color: #011e30;
      color: white;

      @media (max-width: 992px) {
        margin-left: 0;
        margin-top: 20px;
      }
    }
  }

  @media (max-width: 992px) {
    flex-wrap: wrap;
  }
`;
