import React from "react";
import styled, { css } from "styled-components";

const Container = styled.div`
  width: 100%;
`;

const InputWrapper = styled.div`
  position: relative;

  span {
    position: absolute;
    right: 15px;
    top: 50%;
    font-size: 35px;
    line-height: 0;
    color: rgba(0, 0, 0, 0.4);
  }
`;

const StyledInput = styled.input`
  width: 100%;
  max-width: 100%;
  height: 50px;
  padding: 0 10px;
  font-size: 18px;

  ${(props) =>
    props.hideNumberSpinner &&
    css`
      &[type="number"]::-webkit-inner-spin-button,
      &[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    `}
`;

const Label = styled.label`
  font-size: 18px;

  > span.sub-text {
    display: block;
    font-size: 17px !important;
    color: #ff7b00;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Input = (props) => {
  const { label, name, value, additionalText, subText, handleChange, hideNumberSpinner = false, image, isDisabled = false } = props;
  return (
    <>
      <Container>
        <ContentWrapper>
          {image && <img src={image} alt={`${label}`} style={{ opacity: isDisabled ? "0.5" : "1" }} />}
          <Label htmlFor={name} style={{ color: isDisabled ? "grey" : "inherit" }}>
            {label}
            {subText && <span className="sub-text">{subText}</span>}
          </Label>
        </ContentWrapper>
        <InputWrapper>
          {additionalText && <span>{additionalText}</span>}
          <StyledInput hideNumberSpinner={hideNumberSpinner} {...props} onChange={handleChange} value={value} />
        </InputWrapper>
      </Container>
    </>
  );
};

export default Input;
