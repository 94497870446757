import React, { useContext } from "react";
import { Radio } from "../../..";
import AppContext from "../../../../store/AppContext";
import Hardwall from "../../../../assets/small/wall-hardwall.png";
import Softwall from "../../../../assets/small/wall-softwall.png";
import Hybrid from "../../../../assets/small/wall-hybrid.png";

const ShellForm = () => {
  const { state } = useContext(AppContext);

  return (
    <>
      <Radio title="Hardwall" image={Hardwall} parentKey="shell" selectionKey="hardwall" selected={state.shell.selection === "hardwall"} />
      <Radio title="Softwall" image={Softwall} parentKey="shell" selectionKey="softwall" selected={state.shell.selection === "softwall"} />
      <Radio title="Hybrid" image={Hybrid} parentKey="shell" selectionKey="hybrid" selected={state.shell.selection === "hybrid"} />
    </>
  );
};

export default ShellForm;
