import React, { useContext } from "react";
import styled from "styled-components";
import { QuantityWrapper, Select } from "../../..";
import AppContext from "../../../../store/AppContext";
import ElectricalSockets from "../../../../assets/small/electrical-sockets.png";
import NetworkSockets from "../../../../assets/small/network-sockets.png";

const InputWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const AMPForm = () => {
  const { state, handleUpdateState } = useContext(AppContext);

  const handleInputChange = (e, label) => {
    handleUpdateState({
      electrical: {
        ...state.electrical,
        [label]: e.target.value,
        complete: true,
      },
    });
  };

  return (
    <>
      <InputWrapper>
        <QuantityWrapper>
          <Select label="13-Amp Electrical Sockets" image={ElectricalSockets} name="electrical-sockets" handleChange={(e) => handleInputChange(e, "electrical-sockets")} value={state.electrical["electrical-sockets"]}>
            <option value="0">0</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
            <option value="7">7</option>
            <option value="8">8</option>
            <option value="9">9</option>
            <option value="10">10</option>
          </Select>
        </QuantityWrapper>
        <QuantityWrapper>
          <Select label="CAT6 Network Sockets" image={NetworkSockets} name="network-sockets" handleChange={(e) => handleInputChange(e, "network-sockets")} value={state.electrical["network-sockets"]}>
            <option value="0">0</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
            <option value="7">7</option>
            <option value="8">8</option>
            <option value="9">9</option>
            <option value="10">10</option>
          </Select>
        </QuantityWrapper>
      </InputWrapper>
    </>
  );
};

export default AMPForm;
